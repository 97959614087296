import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import { TranslationSetFragmentDoc, CoverImageFragmentDoc, ContentTagMembershipFragmentDoc, ContentSharingPermissionFragmentDoc, AutomationFragmentDoc, ImageUrlsFragmentDoc, ContentQrCodeFragmentDoc, AiGenerateModuleRequestFragmentDoc, MediaFragmentDoc, UserSelectionFragmentDoc, PathInstanceThinFragmentDoc, BlastSentRecordFragmentDoc } from '../../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RecentCourseFeedbackQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RecentCourseFeedbackQuery = { __typename?: 'Query', courseFeedback: Array<{ __typename?: 'CourseFeedback', id: number, createdAt: string, comment?: string | null, rating: number, courseInstance: { __typename?: 'CourseInstance', id: number, completedAt?: string | null, user: { __typename?: 'Employee', id: number, name: string }, course: { __typename?: 'Course', id: number, libraryItem: { __typename?: 'LibraryItem', id: string, name: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } } } } }> };

export type CourseFeedbackFullFragment = { __typename?: 'CourseFeedback', id: number, createdAt: string, comment?: string | null, rating: number, courseInstance: { __typename?: 'CourseInstance', id: number, completedAt?: string | null, user: { __typename?: 'Employee', id: number, name: string }, course: { __typename?: 'Course', id: number, libraryItem: { __typename?: 'LibraryItem', id: string, name: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } } } } };

export type RecentFeedbackCourseInstanceFragment = { __typename?: 'CourseInstance', id: number, completedAt?: string | null, user: { __typename?: 'Employee', id: number, name: string }, course: { __typename?: 'Course', id: number, libraryItem: { __typename?: 'LibraryItem', id: string, name: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } } } };

export const RecentFeedbackCourseInstanceFragmentDoc = gql`
    fragment RecentFeedbackCourseInstance on CourseInstance {
  id
  completedAt
  user {
    id
    name
  }
  course {
    id
    libraryItem {
      id
      name {
        ...TranslationSet
      }
    }
  }
}
    ${TranslationSetFragmentDoc}`;
export const CourseFeedbackFullFragmentDoc = gql`
    fragment CourseFeedbackFull on CourseFeedback {
  id
  createdAt
  comment
  rating
  courseInstance {
    ...RecentFeedbackCourseInstance
  }
}
    ${RecentFeedbackCourseInstanceFragmentDoc}`;
export const RecentCourseFeedbackDocument = gql`
    query RecentCourseFeedback {
  courseFeedback: AdminCourseFeedback(pagination: {limit: 20}) {
    ...CourseFeedbackFull
  }
}
    ${CourseFeedbackFullFragmentDoc}`;

/**
 * __useRecentCourseFeedbackQuery__
 *
 * To run a query within a React component, call `useRecentCourseFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentCourseFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentCourseFeedbackQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecentCourseFeedbackQuery(baseOptions?: Apollo.QueryHookOptions<RecentCourseFeedbackQuery, RecentCourseFeedbackQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecentCourseFeedbackQuery, RecentCourseFeedbackQueryVariables>(RecentCourseFeedbackDocument, options);
      }
export function useRecentCourseFeedbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecentCourseFeedbackQuery, RecentCourseFeedbackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecentCourseFeedbackQuery, RecentCourseFeedbackQueryVariables>(RecentCourseFeedbackDocument, options);
        }
export type RecentCourseFeedbackQueryHookResult = ReturnType<typeof useRecentCourseFeedbackQuery>;
export type RecentCourseFeedbackLazyQueryHookResult = ReturnType<typeof useRecentCourseFeedbackLazyQuery>;
export type RecentCourseFeedbackQueryResult = Apollo.QueryResult<RecentCourseFeedbackQuery, RecentCourseFeedbackQueryVariables>;