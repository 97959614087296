import * as React from "react";
import { IconProps } from "../types";

const CircleXFillIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M9 1C4.589 1 1 4.589 1 9C1 13.411 4.589 17 9 17C13.411 17 17 13.411 17 9C17 4.589 13.411 1 9 1ZM12.28 11.22C12.573 11.513 12.573 11.988 12.28 12.281C12.134 12.427 11.942 12.501 11.75 12.501C11.558 12.501 11.366 12.428 11.22 12.281L9 10.061L6.78 12.281C6.634 12.427 6.442 12.501 6.25 12.501C6.058 12.501 5.866 12.428 5.72 12.281C5.427 11.988 5.427 11.513 5.72 11.22L7.94 9L5.72 6.78C5.427 6.487 5.427 6.012 5.72 5.719C6.013 5.426 6.488 5.426 6.781 5.719L9.001 7.939L11.221 5.719C11.514 5.426 11.989 5.426 12.282 5.719C12.575 6.012 12.575 6.487 12.282 6.78L10.062 9L12.282 11.22H12.28Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default CircleXFillIcon;
