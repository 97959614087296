import React from "react";
import { CartesianGrid, Line, LineChart, XAxis } from "recharts";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@src/ui/chart";
import { gql } from "@apollo/client";
import { useAvgTrainingFeedbackWidgetQuery } from "@src/components/home/AvgTrainingFeedbackChart.generated";
import { subYears } from "date-fns";

const NOW = new Date();
const CHART_CONFIG = {
  average: {
    label: "Average",
  },
};

const AvgTrainingFeedbackChart: React.FC = () => {
  const { data } = useAvgTrainingFeedbackWidgetQuery({
    variables: {
      input: { since: { value: subYears(NOW, 1.5).toISOString() } },
    },
  });
  const formattedData = React.useMemo(
    () => data?.AvgTrainingFeedback || [],
    [data],
  );
  const ticks =
    formattedData && formattedData.length > 0
      ? [formattedData[0].label, formattedData[formattedData.length - 1].label]
      : [];
  return (
    <ChartContainer config={CHART_CONFIG} className="mt-5 h-[250px] w-full">
      <LineChart accessibilityLayer data={formattedData}>
        <CartesianGrid
          vertical={true}
          horizontal={false}
          strokeDasharray="6 6"
        />
        <XAxis
          dataKey="label"
          tickLine={false}
          axisLine={false}
          tickMargin={8}
          xAxisId={0}
          height={0}
        />
        <XAxis
          dataKey="label"
          tickLine={false}
          axisLine={false}
          tickMargin={8}
          ticks={ticks}
          allowDuplicatedCategory={false}
          type="category"
          xAxisId={1}
          interval="preserveStartEnd"
        />
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent />}
          isAnimationActive={false}
        />
        <Line
          dataKey="average"
          type="monotone"
          strokeWidth={2}
          dot={false}
          stroke="#4778F5"
          isAnimationActive={false}
        />
      </LineChart>
    </ChartContainer>
  );
};

gql`
  query AvgTrainingFeedbackWidget($input: AvgTrainingFeedbackInput!) {
    AvgTrainingFeedback(input: $input) {
      week
      average
      label
    }
  }
`;

export default AvgTrainingFeedbackChart;
