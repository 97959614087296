import useLanguage from "@src/hooks/useLanguage";
import { useTranslation } from "@src/utils/translationSets";
import { useMemo } from "react";

export const DESCRIPTION_ENGLISH_TRANSLATION = "Description";
export const NO_INCOMPLETE_ASSIGNMENTS_ENGLISH_TRANSLATION =
  "No incomplete assignments";
export const NAME_ENGLISH_TRANSLATION = "Name";
export const TYPE_ENGLISH_TRANSLATION = "Type";
export const ASSIGNED_AT_ENGLISH_TRANSLATION = "Assigned at";
export const STARTED_AT_ENGLISH_TRANSLATION = "Started at";
export const DUE_DATE_ENGLISH_TRANSLATION = "Due Date";
export const COURSE_ENGLISH_TRANSLATION = "Course";
export const MODULE_ENGLISH_TRANSLATION = "Module";
export const TOTAL_ATTEMPTS_ENGLISH_TRANSLATION = "Total attempts";
export const ASSIGNED_ENGLISH_TRANSLATION = "Assigned";
export const REASSIGNED_ENGLISH_TRANSLATION = "Reassigned";

type Return = {
  noIncompleteAssignments: string;
  name: string;
  type: string;
  assignedAt: string;
  startedAt: string;
  dueDate: string;
  totalAttempts: string;
  course: string;
  module: string;
  assigned: string;
  reassigned: string;
  description: string;
};

export const useMyTrainingPageContentTranslations = (): Return => {
  const language = useLanguage();
  const noIncompleteAssignmentsTranslation = useTranslation(
    NO_INCOMPLETE_ASSIGNMENTS_ENGLISH_TRANSLATION,
    language,
  );
  const nameTranslation = useTranslation(NAME_ENGLISH_TRANSLATION, language);
  const typeTranslation = useTranslation(TYPE_ENGLISH_TRANSLATION, language);
  const assignedAtTranslation = useTranslation(
    ASSIGNED_AT_ENGLISH_TRANSLATION,
    language,
  );
  const startedAtTranslation = useTranslation(
    STARTED_AT_ENGLISH_TRANSLATION,
    language,
  );
  const dueDateTranslation = useTranslation(
    DUE_DATE_ENGLISH_TRANSLATION,
    language,
  );
  const totalAttemptsTranslation = useTranslation(
    TOTAL_ATTEMPTS_ENGLISH_TRANSLATION,
    language,
  );
  const courseTranslation = useTranslation(
    COURSE_ENGLISH_TRANSLATION,
    language,
  );
  const moduleTranslation = useTranslation(
    MODULE_ENGLISH_TRANSLATION,
    language,
  );
  const assignedTranslation = useTranslation(
    ASSIGNED_ENGLISH_TRANSLATION,
    language,
  );
  const reassignedTranslation = useTranslation(
    REASSIGNED_ENGLISH_TRANSLATION,
    language,
  );
  const descriptionTranslation = useTranslation(
    DESCRIPTION_ENGLISH_TRANSLATION,
    language,
  );

  return useMemo(
    () => ({
      noIncompleteAssignments:
        noIncompleteAssignmentsTranslation.text ||
        NO_INCOMPLETE_ASSIGNMENTS_ENGLISH_TRANSLATION,
      name: nameTranslation.text || NAME_ENGLISH_TRANSLATION,
      type: typeTranslation.text || TYPE_ENGLISH_TRANSLATION,
      assignedAt: assignedAtTranslation.text || ASSIGNED_AT_ENGLISH_TRANSLATION,
      startedAt: startedAtTranslation.text || STARTED_AT_ENGLISH_TRANSLATION,
      dueDate: dueDateTranslation.text || DUE_DATE_ENGLISH_TRANSLATION,
      course: courseTranslation.text || COURSE_ENGLISH_TRANSLATION,
      module: moduleTranslation.text || MODULE_ENGLISH_TRANSLATION,
      totalAttempts:
        totalAttemptsTranslation.text || TOTAL_ATTEMPTS_ENGLISH_TRANSLATION,
      assigned: assignedTranslation.text || ASSIGNED_ENGLISH_TRANSLATION,
      reassigned: reassignedTranslation.text || REASSIGNED_ENGLISH_TRANSLATION,
      description:
        descriptionTranslation.text || DESCRIPTION_ENGLISH_TRANSLATION,
    }),
    [
      noIncompleteAssignmentsTranslation.text,
      nameTranslation.text,
      typeTranslation.text,
      assignedAtTranslation.text,
      startedAtTranslation.text,
      dueDateTranslation.text,
      courseTranslation.text,
      moduleTranslation.text,
      totalAttemptsTranslation.text,
      assignedTranslation.text,
      reassignedTranslation.text,
      descriptionTranslation.text,
    ],
  );
};
