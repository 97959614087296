import React, { FC } from "react";
import { useToast } from "@hooks/useToast";
import { useTracking } from "@hooks/useTracking";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@ui/alert-dialog";
import { cn } from "@src/ui/lib/utils";
import { textVariants } from "@src/ui/text";
import { Button } from "@src/ui/button";
import {
  InactiveUsersSheetEmployeeFragment,
  useUpdateInactiveUsersSheetMutation,
} from "@src/components/home/hooks/useGetInactiveUsers.generated";
import { GraphqlOperations } from "@src/types.generated";

type Props = {
  users: InactiveUsersSheetEmployeeFragment[];
  open: boolean;
  onSuccess?: () => void;
  onOpenChange: (open: boolean) => void;
  children?: React.ReactNode;
};

const BulkDeactivateUserAlertDialog: FC<Props> = ({
  open,
  onOpenChange,
  onSuccess,
  users,
  children,
}) => {
  const { addErrorToast, addToast } = useToast();
  const { trackEvent } = useTracking();
  const [updateInactiveUsers, { loading }] =
    useUpdateInactiveUsersSheetMutation({
      refetchQueries: [GraphqlOperations.Query.InactiveUsersSheet],
    });
  const bulkDeactivateUsers = React.useCallback(() => {
    const userIds = users.map((user) => user.id);
    updateInactiveUsers({
      variables: {
        toDeactivate: userIds,
        toKeep: [],
      },
      onCompleted: (data) => {
        if (data.removeEmployees.success) {
          trackEvent("bulk_removed_employees", {
            count: users.length,
          });
          addToast({
            message: `${users.length} user${users.length !== 1 ? "s" : ""} ${users.length !== 1 ? "have" : "has"} been deactivated`,
          });
          onOpenChange(false);
          onSuccess?.();
        } else {
          addErrorToast({
            message: `There was an error deactivating the user${users.length !== 1 ? "s" : ""}`,
            callsite: "bulk_deactivate_employees",
          });
        }
      },
      onError: (error) => {
        addErrorToast({
          error: error,
          message: `There was an error deactivating the user${users.length !== 1 ? "s" : ""}`,
          callsite: "bulk_deactivate_employees",
        });
      },
    });
  }, [
    users,
    updateInactiveUsers,
    trackEvent,
    addToast,
    onOpenChange,
    onSuccess,
    addErrorToast,
  ]);
  const userName = users.length === 1 ? users[0].name : undefined;
  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {users.length === 1 ? `Deactivate user` : "Deactivate users"}
          </AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want to deactivate{" "}
            <span className="underline">
              {users.length === 1 ? userName : `${users.length} users`}
            </span>{" "}
            from Opus?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <ul className="ml-4 list-disc [&>li]:mt-2">
          <li className={cn(textVariants({ variant: "P2" }))}>
            They will no longer have access to the Opus app.
          </li>
          <li className={cn(textVariants({ variant: "P2" }))}>
            They will appear in the "deactivated" section of the dashboard.
          </li>
          <li className={cn(textVariants({ variant: "P2" }))}>
            You can always reactivate them later and they will still have all of
            their training history.
          </li>
        </ul>
        <AlertDialogFooter>
          <AlertDialogCancel asChild>
            <Button variant="outline">Cancel</Button>
          </AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button
              variant="destructive"
              disabled={loading}
              onClick={bulkDeactivateUsers}
            >
              Deactivate {users.length === 1 ? "user" : "users"}
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default BulkDeactivateUserAlertDialog;
