import Widget from "./Widget";
import { Skeleton } from "@src/ui/skeleton";
import StarOutlineIcon from "@src/ui/icons/18px/star-outline";
import Text from "@src/ui/text";
import EmptyWidgetContent from "./EmptyWidgetContent";
import { useAverageCourseFeedbackQuery } from "@src/components/contentDetail/courseDetail/feedback/FeedbackSummary.generated";
import AvgTrainingFeedbackChart from "@src/components/home/AvgTrainingFeedbackChart";

const AvgTrainingFeedbackWidget: React.FC = () => {
  const { data, loading } = useAverageCourseFeedbackQuery({
    variables: {
      input: {},
    },
  });
  const average = data?.AverageCourseFeedback || 0;

  if (!loading && average === 0) {
    return (
      <Widget title="Average Training Feedback" Icon={StarOutlineIcon}>
        {!loading && !average && (
          <EmptyWidgetContent
            illustration={
              <StarOutlineIcon className="size-8 text-muted-foreground" />
            }
            description="You'll be able to identify feedback trends here"
          />
        )}
      </Widget>
    );
  }
  return (
    <Widget
      title="Average Training Feedback"
      Icon={StarOutlineIcon}
      href={{ pathname: "/reporting/course-feedback" }}
    >
      {loading ? (
        <div>
          {loading && <Skeleton className="h-10 w-20 rounded-md" />}
          {loading && <Skeleton className="mt-5 h-[250px] w-full rounded-xl" />}
        </div>
      ) : (
        <div>
          <div className="flex items-baseline gap-1">
            <Text type="H1" fontWeight="SemiBold">
              {average.toFixed(1)}
            </Text>
            <Text type="P3" fontWeight="SemiBold" className="text-gray-7">
              out of 5
            </Text>
          </div>
          <AvgTrainingFeedbackChart />
        </div>
      )}
    </Widget>
  );
};

export default AvgTrainingFeedbackWidget;
