import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AverageCourseFeedbackQueryVariables = Types.Exact<{
  input: Types.CourseFeedbacksInput;
}>;


export type AverageCourseFeedbackQuery = { __typename?: 'Query', AverageCourseFeedback?: number | null };

export type CourseFeedbackSummary_CourseFeedbacksQueryVariables = Types.Exact<{
  input: Types.CourseFeedbacksInput;
}>;


export type CourseFeedbackSummary_CourseFeedbacksQuery = { __typename?: 'Query', CourseFeedbacks: { __typename?: 'CourseFeedbacks', totalCount: number, objects: Array<{ __typename?: 'CourseFeedback', id: number, rating: number }> } };


export const AverageCourseFeedbackDocument = gql`
    query AverageCourseFeedback($input: CourseFeedbacksInput!) {
  AverageCourseFeedback(input: $input)
}
    `;

/**
 * __useAverageCourseFeedbackQuery__
 *
 * To run a query within a React component, call `useAverageCourseFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useAverageCourseFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAverageCourseFeedbackQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAverageCourseFeedbackQuery(baseOptions: Apollo.QueryHookOptions<AverageCourseFeedbackQuery, AverageCourseFeedbackQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AverageCourseFeedbackQuery, AverageCourseFeedbackQueryVariables>(AverageCourseFeedbackDocument, options);
      }
export function useAverageCourseFeedbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AverageCourseFeedbackQuery, AverageCourseFeedbackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AverageCourseFeedbackQuery, AverageCourseFeedbackQueryVariables>(AverageCourseFeedbackDocument, options);
        }
export type AverageCourseFeedbackQueryHookResult = ReturnType<typeof useAverageCourseFeedbackQuery>;
export type AverageCourseFeedbackLazyQueryHookResult = ReturnType<typeof useAverageCourseFeedbackLazyQuery>;
export type AverageCourseFeedbackQueryResult = Apollo.QueryResult<AverageCourseFeedbackQuery, AverageCourseFeedbackQueryVariables>;
export const CourseFeedbackSummary_CourseFeedbacksDocument = gql`
    query CourseFeedbackSummary_CourseFeedbacks($input: CourseFeedbacksInput!) {
  CourseFeedbacks(input: $input) {
    totalCount
    objects {
      id
      rating
    }
  }
}
    `;

/**
 * __useCourseFeedbackSummary_CourseFeedbacksQuery__
 *
 * To run a query within a React component, call `useCourseFeedbackSummary_CourseFeedbacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseFeedbackSummary_CourseFeedbacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseFeedbackSummary_CourseFeedbacksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseFeedbackSummary_CourseFeedbacksQuery(baseOptions: Apollo.QueryHookOptions<CourseFeedbackSummary_CourseFeedbacksQuery, CourseFeedbackSummary_CourseFeedbacksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CourseFeedbackSummary_CourseFeedbacksQuery, CourseFeedbackSummary_CourseFeedbacksQueryVariables>(CourseFeedbackSummary_CourseFeedbacksDocument, options);
      }
export function useCourseFeedbackSummary_CourseFeedbacksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CourseFeedbackSummary_CourseFeedbacksQuery, CourseFeedbackSummary_CourseFeedbacksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CourseFeedbackSummary_CourseFeedbacksQuery, CourseFeedbackSummary_CourseFeedbacksQueryVariables>(CourseFeedbackSummary_CourseFeedbacksDocument, options);
        }
export type CourseFeedbackSummary_CourseFeedbacksQueryHookResult = ReturnType<typeof useCourseFeedbackSummary_CourseFeedbacksQuery>;
export type CourseFeedbackSummary_CourseFeedbacksLazyQueryHookResult = ReturnType<typeof useCourseFeedbackSummary_CourseFeedbacksLazyQuery>;
export type CourseFeedbackSummary_CourseFeedbacksQueryResult = Apollo.QueryResult<CourseFeedbackSummary_CourseFeedbacksQuery, CourseFeedbackSummary_CourseFeedbacksQueryVariables>;