import Text from "@src/ui/text";
import Widget from "@src/components/home/Widget";
import { Skeleton } from "@src/ui/skeleton";
import { gql } from "@apollo/client";
import IncompleteAssignmentsSheet from "@src/components/home/IncompleteAssignmentsSheet";
import { useIncompleteAssignmentsWidgetQuery } from "./IncompleteAssignmentsWidget.generated";

const IncompleteAssignmentsWidget: React.FC = () => {
  const { data, loading } = useIncompleteAssignmentsWidgetQuery();
  const incomplete = data?.MyTeamIncompleteAssignments.totalCount || 0;
  const pastDue = data?.AdminEmployeesWithPastDueAssignments.totalCount || 0;
  return (
    <Widget
      title="Incomplete Assignments"
      className="flex-1 self-stretch shadow-none"
    >
      {loading ? (
        <Skeleton className="h-[34px] w-10 rounded-md" />
      ) : (
        <IncompleteAssignmentsSheet>
          <div className="group mr-auto flex cursor-pointer flex-row items-center gap-1 align-middle">
            <Text
              type="H1"
              fontWeight="SemiBold"
              className="group-hover:underline"
            >
              {incomplete}
            </Text>
            {pastDue > 0 && (
              <>
                <div className="rounded-lg bg-orange-1 px-2">
                  <Text
                    type="P2"
                    fontWeight="SemiBold"
                    className="text-orange-10"
                  >
                    ⚠ {pastDue}
                  </Text>
                </div>
                <Text type="P3" className="text-gray-8">
                  people past due
                </Text>
              </>
            )}
          </div>
        </IncompleteAssignmentsSheet>
      )}
    </Widget>
  );
};

gql`
  query IncompleteAssignmentsWidget {
    MyTeamIncompleteAssignments {
      totalCount
    }
    AdminEmployeesWithPastDueAssignments(input: {}) {
      totalCount
    }
  }
`;

export default IncompleteAssignmentsWidget;
