import React from "react";
import Text from "@src/ui/text";
import {
  NUM_DAYS_SINCE_LAST_CONFIRMED_EMPLOYMENT,
  NUM_DAYS_WITHOUT_ACTVITIY,
} from "@src/components/home/hooks/useGetInactiveUsersCounts";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@src/ui/sheet";
import { GetPeopleInput, GraphqlOperations } from "@src/types.generated";
import { getRelativeDaysString } from "@src/components/home/utils";
import PersonAvatar from "@src/deprecatedDesignSystem/components/PersonAvatar";
import { useToast } from "@src/hooks/useToast";
import { Button } from "@src/ui/button";
import CircleCheckmarkOutlineIcon from "@src/ui/icons/18px/circle-checkmark-outline";
import CircleXOutlineIcon from "@src/ui/icons/18px/circle-x-outline";
import BulkDeactivateUserAlertDialog from "@src/components/home/BulkDeactivateUserAlertDialog";
import { useTracking } from "@src/hooks/useTracking";
import PaginatedTable from "@src/deprecatedDesignSystem/components/table/PaginatedTable";
import { DateTime } from "luxon";
import {
  InactiveUsersSheetEmployeeFragment,
  useInactiveUsersSheetLazyQuery,
  useInactiveUsersSheetQuery,
  useUpdateInactiveUsersSheetMutation,
} from "@src/components/home/hooks/useGetInactiveUsers.generated";
import UserNameLocationsRolesTableCell from "@src/components/home/UserNameLocationsRolesTableCell";

type Column = "name" | "lastActivityAt" | "actions";
const exportColumns = ["name", "lastActivityAt", "actions"] as const;
type ExportColumn = (typeof exportColumns)[number];

type Props = {
  children: React.ReactNode;
};

const InactiveUsersSheet: React.FC<Props> = (props) => {
  const { addErrorToast, addToast } = useToast();
  const input: GetPeopleInput = React.useMemo(() => {
    return {
      inactiveSince: {
        value: DateTime.now()
          .minus({ days: NUM_DAYS_WITHOUT_ACTVITIY })
          .toISODate(),
      },
      excludeConfirmedStillEmployedAfter: {
        value: DateTime.now()
          .minus({ days: NUM_DAYS_SINCE_LAST_CONFIRMED_EMPLOYMENT })
          .toISODate(),
      },
    };
  }, []);
  const [updateInactiveUsers, { loading: bulkStillWorksHereLoading }] =
    useUpdateInactiveUsersSheetMutation({
      refetchQueries: [
        GraphqlOperations.Query.InactiveUsersSheet,
        GraphqlOperations.Query.InactiveUsersCount,
      ],
    });
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] =
    React.useState(false);
  const [selectedUsers, setSelectedUsers] = React.useState<
    Set<InactiveUsersSheetEmployeeFragment>
  >(new Set());
  const { trackEvent } = useTracking();
  const bulkStillWorksHere = React.useCallback(
    (users: InactiveUsersSheetEmployeeFragment[]) => {
      const userIds = users.map((user) => user.id);
      updateInactiveUsers({
        variables: {
          toDeactivate: [],
          toKeep: userIds,
        },
        onCompleted: (data) => {
          if (data.removeEmployees.success) {
            trackEvent("bulk_still_works_here", {
              count: userIds.length,
            });
            addToast({
              message: `${userIds.length} user${userIds.length !== 1 ? "s" : ""} confirmed as still employed`,
            });
            setSelectedUsers(new Set());
            setIsDeactivateDialogOpen(false);
          } else {
            addErrorToast({
              message: `There was an error confirming the users' employment status`,
              callsite: "bulk_still_works_here",
            });
          }
        },
        onError: (error) => {
          addErrorToast({
            error: error,
            message:
              "There was an error confirming the users' employment status",
            callsite: "bulk_still_works_here",
          });
        },
      });
    },
    [updateInactiveUsers, trackEvent, addToast, addErrorToast],
  );
  const handleBulkDeactivateSuccess = React.useCallback(() => {
    setSelectedUsers(new Set());
    setIsDeactivateDialogOpen(false);
  }, []);
  return (
    <Sheet>
      <SheetTrigger asChild>{props.children}</SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Inactive users</SheetTitle>
          <SheetDescription>
            Users who have not been active for {NUM_DAYS_WITHOUT_ACTVITIY} days
          </SheetDescription>
        </SheetHeader>
        <PaginatedTable<
          InactiveUsersSheetEmployeeFragment,
          Column,
          typeof useInactiveUsersSheetQuery,
          typeof useInactiveUsersSheetLazyQuery,
          ExportColumn
        >
          headerStickyPositioning={0}
          queryHook={useInactiveUsersSheetQuery}
          lazyQueryHook={useInactiveUsersSheetLazyQuery}
          input={input}
          allItemsCountInput={input}
          getItems={(x) => x?.People?.objects ?? []}
          getTotalItemCount={(x) => x?.People?.totalCount ?? 0}
          itemId={(x) => x.id}
          disableRowClickOnColumns={new Set(["actions"])}
          defaultSort={{ column: "lastActivityAt", descending: false }}
          allColumns={["name", "lastActivityAt", "actions"]}
          columnTitles={{
            name: "User",
            lastActivityAt: "Last active",
            actions: "",
          }}
          responsiveness={{
            actions: {
              minWidth: 240,
            },
          }}
          rowHeight={52}
          renderFns={{
            name: (x) => (
              <UserNameLocationsRolesTableCell
                userId={x.id.toString()}
                userName={x.name}
                locations={x.locations.map((loc) => loc.name)}
                roles={x.roles.map((role) => role.name)}
              />
            ),
            lastActivityAt: (x) => (
              <Text
                type="P2"
                fontWeight="Medium"
                className="text-muted-foreground"
              >
                {getRelativeDaysString(x.lastActivityAt || "")}
              </Text>
            ),
            actions: (x) => (
              <div className="flex flex-1 items-center justify-end gap-2">
                <Button
                  variant="outline"
                  size="sm"
                  disabled={bulkStillWorksHereLoading}
                  onClick={() => bulkStillWorksHere([x])}
                >
                  <CircleCheckmarkOutlineIcon className="size-4 text-green-8" />
                  Works here
                </Button>
                <BulkDeactivateUserAlertDialog
                  users={[x]}
                  open={isDeactivateDialogOpen && selectedUsers.has(x)}
                  onOpenChange={(open) => {
                    setIsDeactivateDialogOpen(open);
                    if (!open) setSelectedUsers(new Set());
                  }}
                >
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      setSelectedUsers(new Set([x]));
                      setIsDeactivateDialogOpen(true);
                    }}
                  >
                    <CircleXOutlineIcon className="size-4 text-destructive" />
                    Deactivate
                  </Button>
                </BulkDeactivateUserAlertDialog>
              </div>
            ),
          }}
          multiselect={{
            checkboxColumnWidth: 32,
            renderAvatarFn: (x) => <PersonAvatar person={x} size={"32px"} />,
            actions: (selectedItems) => [
              {
                key: "still-works-here",
                icon: "check-circle",
                label: "Works here",
                onClick: () => bulkStillWorksHere(selectedItems),
              },
              {
                key: "deactivate",
                icon: "close-circle",
                label: "Deactivate",
                onClick: () => {
                  setSelectedUsers(new Set(selectedItems));
                  setIsDeactivateDialogOpen(true);
                },
              },
            ],
          }}
        />
        <BulkDeactivateUserAlertDialog
          users={Array.from(selectedUsers)}
          open={isDeactivateDialogOpen}
          onOpenChange={setIsDeactivateDialogOpen}
          onSuccess={handleBulkDeactivateSuccess}
        />
      </SheetContent>
    </Sheet>
  );
};

export default InactiveUsersSheet;
