import Text from "@ui/text";
import { FC, useMemo } from "react";
import { usePollInterval } from "@src/hooks/usePollInterval";
import { gql } from "@apollo/client";
import { ListPageContent } from "@src/components/layout/ListPage";
import { useMyTrainingPageMyTodoAssignmentsQuery } from "@src/components/my-training/MyTrainingPageContent.generated";
import { useRouter } from "next/router";
import Table from "@src/deprecatedDesignSystem/components/table/Table";
import EmptyState from "@src/deprecatedDesignSystem/components/EmptyState";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import ContentAvatar from "@src/deprecatedDesignSystem/components/ContentAvatar";
import { LibraryItemType } from "@src/types.generated";
import { formatDateShort } from "@src/components/my-training/utils";
import { DateTime } from "luxon";
import usePublishUpdatedContentFeatureFlag from "@src/components/my-training/hooks/usePublishUpdatedContentFeatureFlag";
import { useMyTrainingPageContentTranslations } from "@src/components/my-training/hooks/useMyTrainingPageContentTranslations";

const MyTrainingPageContent: FC = () => {
  const publishUpdatedContentFeatureFlag =
    usePublishUpdatedContentFeatureFlag();
  const pollInterval = usePollInterval(1000 * 60);
  const { data, loading } = useMyTrainingPageMyTodoAssignmentsQuery({
    pollInterval,
  });
  const router = useRouter();
  const assignments = data?.MyTodoAssignments.objects || [];
  const translations = useMyTrainingPageContentTranslations();
  const allColumns = useMemo(() => {
    if (publishUpdatedContentFeatureFlag) {
      return [
        "name",
        "type",
        "assignedAt",
        "totalAttempts",
        "startedAt",
        "dueDate",
      ];
    } else {
      return ["name", "type", "startedAt", "dueDate"];
    }
  }, [publishUpdatedContentFeatureFlag]);

  return (
    <ListPageContent>
      <Table
        loading={loading}
        noDataEmptyState={
          <div className="flex flex-1 self-stretch">
            <EmptyState
              title={translations.noIncompleteAssignments}
              icon="checkmark"
            />
          </div>
        }
        showCount={false}
        allItems={assignments}
        allColumns={allColumns}
        disabledSorts={allColumns}
        columnTitles={{
          name: translations.name,
          type: translations.type,
          assignedAt: translations.assignedAt,
          totalAttempts: translations.totalAttempts,
          startedAt: translations.startedAt,
          dueDate: translations.dueDate,
        }}
        responsiveness={{
          name: {
            flex: 3,
          },
        }}
        onRowClick={(item) => {
          if (item.currentCourseInstance) {
            router.push({
              pathname: "/my-training/course-instance/[id]",
              query: { id: String(item.currentCourseInstance.id) },
            });
          } else if (item.currentPathInstance) {
            router.push({
              pathname: "/my-training/path-instance/[id]",
              query: { id: String(item.currentPathInstance.id) },
            });
          }
        }}
        renderFns={{
          name: (item) => (
            <AutoLayout alignmentVertical="center" spaceBetweenItems={8}>
              <ContentAvatar
                size={32}
                contentType="Module"
                style={{ borderRadius: 8 }}
                coverImage={
                  item.libraryItem.coverImage || item.libraryItem.coverImage
                }
                defaultBackgroundHashKey={item.libraryItem.id}
              />
              <Text type="P2">{item.libraryItem.name.myTranslation}</Text>
            </AutoLayout>
          ),
          type: (item) =>
            item.libraryItem.type === LibraryItemType.Course
              ? translations.course
              : translations.module,
          assignedAt: (item) => {
            const sortedAccessSources = [...item.accessSources].sort((a, b) =>
              DateTime.fromISO(b.createdAt)
                .diff(DateTime.fromISO(a.createdAt))
                .toMillis(),
            );
            const assignedAt = sortedAccessSources[0].createdAt;
            return formatDateShort(assignedAt);
          },
          totalAttempts: (item) => item.attemptsCount,
          startedAt: (item) =>
            formatDateShort(
              item.currentCourseInstance?.startedAt ||
                item.currentPathInstance?.startedAt,
            ),

          dueDate: (item) =>
            formatDateShort(
              item.currentCourseInstance?.dueDate ||
                item.currentPathInstance?.dueDate,
            ),
        }}
        itemId={(item) => item.id}
      />
    </ListPageContent>
  );
};

gql`
  query MyTrainingPageMyTodoAssignmentsCount {
    MyTodoAssignments {
      totalCount
    }
  }
  query MyTrainingPageMyTodoAssignments {
    MyTodoAssignments {
      objects {
        ...MyTrainingPageToDoAssignment
      }
    }
  }
  fragment MyTrainingPageToDoAssignment on Assignment {
    id
    status
    attemptsCount
    accessSources {
      id
      createdAt
    }
    libraryItem {
      ...MyTrainingPageToDoLibraryItem
    }
    currentCourseInstance {
      ...MyTrainingPageToDoCourseInstance
    }
    currentPathInstance {
      ...MyTrainingPageToDoPathInstance
    }
  }
  fragment MyTrainingPageToDoLibraryItem on LibraryItem {
    id
    type
    name {
      ...MyTranslationSet
    }
    coverImage {
      ...CoverImage
    }
    description {
      ...MyTranslationSet
    }
  }
  fragment MyTrainingPageToDoPathInstance on PathInstance {
    id
    startedAt
    completedAt
    percentComplete
    dueDate
  }
  fragment MyTrainingPageToDoCourseInstance on CourseInstance {
    id
    track
    startedAt
    completedAt
    percentComplete
    dueDate
    certificateUrl
  }
`;

export default MyTrainingPageContent;
